import request from '@/utils/request'

export function getDictListByCode(code) {
  return request({
    url: '/dict/getDictListByCode',
    method: 'post',
    params: { code }
  })
}

export function getList(data) {
  return request({
    url: '/dict/getList',
    method: 'post',
    data
  })
}

export function getParentList() {
  return request({
    url: '/dict/getParentList',
    method: 'post'
  })
}

export function addDict(data, pId) {
  return request({
    url: '/dict/saveData',
    method: 'post',
    data,
    params: { pId }
  })
}

export function editDict(data) {
  return request({
    url: '/dict/editData',
    method: 'post',
    data
  })
}

export function enable(data) {
  return request({
    url: '/dict/enable',
    method: 'post',
    params: data
  })
}

export function disable(data) {
  return request({
    url: '/dict/disable',
    method: 'post',
    params: data
  })
}

export function getSysDict(parentCode) {
  return request({
    url: '/dict/getListByParentCode',
    method: 'post',
    params: { parentCode }
  })
}

export function getSysDictListByCode(code) {
  return request({
    url: '/dict/getSysDictListByCode',
    method: 'post',
    params: { code }
  })
}

export function getInfo(data) {
  return request({
    url: '/dict/getInfo',
    method: 'post',
    params: data
  })
}

export function getPage(data) {
  return request({
    url: '/dict/getPage',
    method: 'post',
    data
  })
}

export function updateStatus(data) {
  return request({
    url: '/dict/updateStatus',
    method: 'post',
    params: data
  })
}
