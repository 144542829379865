import request from '@/utils/request'

export function getRoutes() {
  return request({
    url: '/routes',
    method: 'get'
  })
}

export function getRoles() {
  return request({
    url: '/roles',
    method: 'get'
  })
}

export function addRole(data) {
  return request({
    url: '/ltcloud/role/save',
    method: 'post',
    data
  })
}

export function getRoleList() {
  return request({
    url: `/ltcloud/role/getAllList`,
    method: 'get'
  })
}

export function getList(data) {
  return request({
    url: '/ltcloud/role/getList',
    method: 'post',
    data
  })
}

export function editRole(data) {
  return request({
    url: `/ltcloud/role/edit`,
    method: 'post',
    data
  })
}

export function deleteRole(id) {
  return request({
    url: `/ltcloud/role/${id}`,
    method: 'delete'
  })
}

export function getNewRoleAllList(departid) {
  return request({
    url: `/ltcloud/role/getNewAllList`,
    method: 'get',
    params: { departid }
  })
}

export function saveSy(data) {
  return request({
    url: `/ltcloud/role/saveSyId`,
    method: 'post',
    data
  })
}

export function getPartList(data) {
  return request({
    url: `/ltcloud/role/getPartList`,
    method: 'post',
    params: data
  })
}

export function getCityPartList(data) {
  return request({
    url: `/ltcloud/role/getCityPartList`,
    method: 'post',
    params: data
  })
}

// 根据角色id获取菜单
export function getRoleMenuList(data) {
  return request({
    url: '/ltcloud/role/getRoleMenuList',
    method: 'post',
    params: data
  })
}

// 保存角色菜单
export function saveMenu(data) {
  return request({
    url: '/ltcloud/role/saveMenu',
    method: 'post',
    data
  })
}

// 获取用户菜单菜单
export function getMenu() {
  return request({
    url: '/ltcloud/user/getMenu',
    method: 'post'
  })
}
